.title_icon {
  color: var(--xemelgo-blue);
  border: 4px solid;
  border-color: var(--xemelgo-light-blue);
  border-radius: 40px;
  padding: 3px;
}

.modal_container {
  width: 960px;
  max-width: 960px !important;
}

.body_container {
  padding: 26px 35px 30px 35px;
  display: flex;
  flex-direction: column;
  font-size: var(--font-size-base);
  font-weight: bold;
  background: var(--app-background);
}

.input_group {
  flex-wrap: wrap;
  gap: 30px;
  flex: 1;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding-top: 15px;
}

.input_container {
  font-size: var(--font-size-small);
  font-weight: bold;
  width: 200px;
}

.section_container {
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  padding-bottom: 35px;
}

.add_detector_button {
  width: 170px;
  align-self: end;
  cursor: pointer;
  font-weight: 600;
  color: var(--app-blue);
  text-align: right;
  font-size: var(--font-size-small);
}

.top_separator {
  border-top: 1px solid var(--app-border-grey);
}

.required_asterisk {
  color: var(--status-red);
}
