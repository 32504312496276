.detail_group {
  min-height: 5px;
  margin: 10px;
  padding: 5px;
  display: flex;
  flex-direction: row;
  line-height: 20px;
}

.detail_label {
  margin-right: 5px;
  font-size: 12px;
  font-weight: bold;
  flex: 0.4;
  text-align: right;
}

.detail_value {
  font-size: 14px;
  text-align: left;
  resize: none;
  border: none;
  background: transparent;
  overflow: visible;
  flex: 0.6;
  padding: 0;
}

.detail_value_input {
  height: 25px;
  font-size: 12px;
  text-align: left;
  border-radius: 2px;
  border: 1px solid var(--app-border-grey);
  resize: none;
  background: var(--app-white);
  align-self: center;
  align-items: center;
  align-content: center;
  flex: 0.6;
  overflow: hidden;
}

.detail_text {
  font-size: 14px;
  resize: none;
  border: none;
  background: transparent;
  overflow: visible;
  flex: 0.6;
  padding: 0;
}

.clickable_text {
  cursor: pointer;
  color: var(--app-blue);
}

.date_time_value {
  font-size: 14px;
  text-align: left;
  resize: none;
  border: none;
  background: transparent;
  overflow: visible;
  flex: 1;
  overflow: hidden;
  padding: 0;
}

.date_time_value_input {
  height: 25px;
  width: 140px;
  font-size: 12px;
  text-align: left;
  resize: none;
  border: none;
  background: var(--app-white);
  align-self: center;
  align-items: center;
  align-content: center;
  flex: 1;
  overflow: hidden;
}

.date_time_with_clear {
  height: 25px;
  width: 170px;
  border-radius: 2px;
  border: 1px solid var(--app-border-grey);
  resize: none;
  background: var(--app-white);
  align-self: center;
  align-items: center;
  align-content: center;
  flex: 1;
  overflow: hidden;
}

.clear_icon {
  position: relative;
  margin-top: 0%;
  margin-left: 5%;
  resize: none;
  border-radius: 50%;
  background-color: var(--app-white);
  color: var(--neutral-primary);
  max-width: 10px;
  max-height: 10px;
}

.clear_icon:hover {
  cursor: pointer;
  background-color: var(--app-blue);
  color: var(--app-white);
}

.detail_value_input:focus {
  border: 1px solid var(--app-blue);
}

.react_switch {
  vertical-align: middle;
  text-align: left;
  flex: 0.6;
}

.date_time {
  display: flex;
  flex: 0.6;
  flex-direction: row;
  text-align: center;
}

.search_container {
  font-size: 12px;
  text-align: left;
  align-self: center;
  align-items: center;
  align-content: center;
  flex: 0.6;
}

.blue_btn {
  background: var(--app-blue);
  color: var(--app-white);
}

.gray_btn {
  background: var(--app-border-grey);
  color: var(--text-primary);
}

.btn {
  text-transform: none;
  letter-spacing: 0.04rem;
  border-radius: 0.2em;
  cursor: pointer;
  padding: 7px 16px;
  font-size: 13px;
  margin: 0 12px 8px;
}

.white_btn {
  background: var(--app-white);
  color: var(--app-blue);
  border: 1px solid var(--app-blue);
  padding: 6px 16px;
}

.white_btn:hover {
  background-color: var(--xemelgo-light-blue);
}

.search_input {
  line-height: 14px;
  border-width: 1px;
  border-radius: 2px;
  padding: 6px 4px 4px;
}

.exp_date_field {
  flex: 0.6 1;
}

.exp_date_label {
  display: flex;
}
