.spinner {
  border: 4px dotted #4b9cdb; /* Create dotted border with the desired color */
  border-radius: 50%; /* Make it circular */
  width: 40px;
  height: 40px;
  border-left-color: transparent; /* Makes one side transparent for spinner effect */
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
