.information_container {
  display: flex;
  flex-direction: column;
  position: relative;
  max-height: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  transition: width 500ms ease-in-out;
  animation: fadeOut 500ms;
  animation-fill-mode: both;
}
