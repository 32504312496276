.fullscreen_button {
  margin-right: 15px;
  cursor: pointer;
  color: var(--app-blue);
  display: flex;
  flex-direction: column;
  align-items: center;
  user-select: none;
}

.fullscreen_icon {
  color: var(--app-blue);
}

.flex_row {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 60px;
  height: 45px;
}

.status_text {
  font-size: 19px;
  font-weight: 600;
  color: var(--status-orange);
}
