.loading_container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.outer_container {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inner_container {
  overflow: hidden;
}
