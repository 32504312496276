.item-association-body {
  padding: 0px;
  width: 100%;
}

.item-association-title {
  width: 100%;
}

.item-association-header {
  margin: 0px !important;
  padding: 0px;
}

.item-association-footer {
  border-top: 1.6px solid var(--app-border-grey);
  padding: 0px;
  background: var(--app-white);
}
