/* Input Container */
.input_container {
  position: relative;
  padding-top: 0.4rem;
  width: 100%;
}

/* Input Wrapper */
.input_wrapper {
  position: relative;
  border-bottom: 1px solid var(--app-border-grey);
  padding: 8px;
  transition: border-color 0.3s ease;
}

.input_wrapper.focused {
  border-color: inherit; /* Inherit from inline style */
}

.input_wrapper.error {
  border-color: var(--status-red);
}

/* Input Field */
.input_wrapper input {
  width: 100%;
  border: none;
  outline: none;
  background: transparent;
  font-size: 1rem;
  padding-top: 10px;
}

.input_wrapper input:-webkit-autofill {
  -webkit-text-fill-color: var(--app-white);
}

.input_wrapper input:disabled {
  background-color: transparent;
}

/* Label */
.input_wrapper label {
  position: absolute;
  top: 50%;
  left: 0px;
  transform: translateY(-50%);
  color: inherit; /* Dynamic color applied via inline style */
  font-size: 1rem;
  transition: all 0.3s ease;
  pointer-events: none;
}

/* Apply active style when input is focused or has a value */
.input_wrapper.focused label,
.input_wrapper.has_value label {
  top: 0;
  font-size: 0.8rem;
}

.input_wrapper.focused label {
  color: inherit; /* Dynamic color applied via inline style */
}

.input_wrapper.error.focused label {
  color: var(--status-red);
}
