.tab_bar {
  border-bottom: 1px solid var(--app-border-grey);
}

.tab_content {
  margin: 20px 20px 0 20px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.title_text {
  font-size: 26px;
  font-weight: 700;
  color: var(--text-primary);
  margin-bottom: 10px;
}

.loading_skeleton_container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.skeleton_table {
  width: 90%;
  height: 100px;
}
