.detail_img {
  max-height: 160px;
}

.top_group {
  flex-direction: row;
  display: flex;
  text-align: center;
  padding: 0px 5px;
}

.title_group {
  width: 90%;
  padding: 0px 5px 5px 10%;
}

.edit_icon {
  position: relative;
  right: -10px;
  cursor: pointer;
  border-radius: 50%;
  padding: 3px;
  background-color: var(--app-white);
  color: var(--neutral-primary);
}

.edit_icon:hover {
  background: var(--app-blue);
  color: var(--app-white);
}

.title_label {
  font-size: 16px;
}

.title_value {
  font-size: 18px;
  font-weight: bold;
  margin-top: 5px;
}

.title_value_input {
  height: 30px;
  padding: 5px;
  font-size: 14px;
  text-align: left;
  margin-left: 5px;
  border-radius: 2px;
  border: 1px solid var(--app-border-grey);
  width: 90%;
  margin-top: 10px;
  margin-bottom: 20px;
  resize: none;
  overflow: hidden;
}
.title_value_input:focus {
  border: 1px solid var(--app-blue);
}

.bottom_group {
  padding: 5px;
}

.cancel_btn:hover {
  box-shadow: 0px 0px 4px 0 rgba(0, 0, 0, 0.1), 0 0px 20px 0 rgba(0, 0, 0, 0.1);
}

.save_btn:hover {
  box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.2), 0 0px 20px 0 rgba(0, 0, 0, 0.25);
}

.edit_form_buttons {
  display: flex;
  justify-content: center;
  padding: 0.5rem 0.625rem 0.5rem 0.625rem;
}

.blue_btn {
  background: var(--app-blue);
  color: var(--app-white);
}

.gray_btn {
  background: var(--app-border-grey);
  color: var(--text-primary);
}

.btn {
  text-transform: none;
  letter-spacing: 0.04rem;
  border-radius: 0.2em;
  cursor: pointer;
  padding: 7px 16px;
  font-size: 13px;
  margin: 0 12px 8px;
}

.white_btn {
  background: var(--app-white);
  color: var(--app-blue);
  border: 1px solid var(--app-blue);
  padding: 6px 16px;
}

.white_btn:hover {
  background-color: var(--xemelgo-light-blue);
}

.detail_card {
  width: 100%;
  box-shadow: none;
  position: relative;
}

.detail_card_body {
  text-align: center;
}

.status {
  margin: 2px;
  border-radius: 15px;
  color: var(--app-white);
  padding: 3px 12px;
  font-weight: 600;
  font-size: 12px;
}

.status_text {
  margin-top: 0.1em;
  font-weight: 600;
}
.status_group {
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.dropdown_toggle {
  position: absolute;
  right: 15px;
  color: var(--app-blue);
}

.remarks_submit_button {
  padding: 7px 20px 7px 20px;
  background-color: var(--app-blue);
  color: var(--app-white);
  border-radius: 0.2em;
  font-size: 13px;
  margin-left: 25px;
}

.remarks_cancel_button {
  padding: 7px 20px 7px 20px;
  color: var(--app-blue);
  font-size: 13px;
}

.remarks_text {
  resize: none;
  width: 100%;
  border: 1px solid var(--app-border-grey);
  font-size: 14px;
}

.remarks_title {
  font-size: 16px;
}

.remarks_container {
  border-top: 1px solid var(--app-border-grey);
  padding: 15px 0px 10px 0px;
  margin-top: 15px;
}

.remarks_button_container {
  display: flex;
  justify-content: flex-end;
}
