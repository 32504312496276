.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  border-right: 1px solid var(--app-border-grey);
  overflow-x: hidden;
  padding: 10px;
}

.empty_filter_container {
  align-self: center;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.empty_filter_text {
  text-align: center;
  color: var(--app-lightgrey);
}
