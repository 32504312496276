.group {
  flex: 1;
}

.chart_slide_container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.loading {
  text-align: center;
}

.title_text {
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  margin: 0 auto;
  padding: 0;
}

.sub_title_text {
  font-size: 18px;
  text-align: center;
  font-weight: 400;
  color: grey;
  margin: 0 auto;
  padding: 0;
}

@media only screen and (max-width: 1380px) {
  .title_text {
    font-size: 18px;
    text-align: center;
    font-weight: bold;
    margin: 0 auto;
    padding: 0;
  }
  .my_pretty_chart_container {
    padding: 0;
    display: flex;
    margin: 0 auto;
  }
}
