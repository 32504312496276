.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: var(--app-blue);
  border-radius: 3px;
  color: var(--app-white);
  height: 35px;
  padding: 0px 20px;
  font-weight: bold;
}

.button:hover {
  opacity: 0.9;
}

.button:active {
  opacity: 0.9;
}
