.table_container {
  display: flex;
  flex-direction: column;
  margin: 10px;
  padding-bottom: 20px;
}

.title_label {
  display: flex;
  flex-direction: row;
  font-size: 24px;
  font-weight: bold;
}

.table_header {
  border-width: 0px;
  padding: 6px 12px 6px 0px;
  border-radius: 3px 3px 0 0;
  background-color: var(--app-blue);
}

.table_header * {
  font-size: 15px;
  font-weight: 700;
  color: var(--app-white) !important;
}

.table_row {
  border-right: 1px solid var(--app-border-grey);
  border-left: 1px solid var(--app-border-grey);
}

.table_item_row {
  align-items: center;
  padding: 8px 12px 8px 0px;
}

.table_item_row:last-child {
  border-radius: 0 0 3px 3px;
}
