.title_icon {
  color: var(--xemelgo-blue);
  border: 4px solid;
  border-color: var(--xemelgo-light-blue);
  border-radius: 40px;
  padding: 3px;
}

.modal_container {
  width: 960px;
  max-width: 960px !important;
}

.location_modal {
  position: absolute;
  top: 0px;
  right: 0;
  transform: none !important;
  max-height: none;
  min-width: 900px;
  height: 100%;
  margin: 0;
}

.loading_circle {
  margin-top: 40%;
  margin-bottom: 5%;
  font-size: 1.5em;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
