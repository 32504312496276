.container {
  margin-right: 12px;
}

.date_range_button {
  cursor: pointer;
  background-color: var(--xemelgo-light-blue);
  color: var(--xemelgo-blue);
  padding: 4px 12px;
  font-size: 15px;
  font-weight: 700;
  border-radius: 3px;
  width: fit-content;
  display: flex;
  column-gap: 10px;
  align-items: center;
  user-select: none;
}

.icon {
  transform: scale(0.8);
}

.header_row_container {
  border-width: 0px;
  padding: 6px 16px;
  border-radius: 3px 3px 0 0;
  background-color: var(--app-blue);
  border: 1px solid var(--app-border-grey);
  border-bottom-width: 0;
}

.header_row_container * {
  font-size: 15px;
  font-weight: 700;
  color: white !important;
}

.table_header {
  padding: 0;
}

.table_item_container {
  margin-bottom: 0;
  padding: 0;
}

.table_item_row {
  padding: 8px 16px;
  border-right: 1px solid var(--app-border-grey);
  border-left: 1px solid var(--app-border-grey);
}

.table_item_row:last-child {
  border-radius: 0 0 3px 3px;
}

.loading_table_item_row {
  padding: 1.5px 16px;
  border-right: 1px solid var(--app-border-grey);
  border-left: 1px solid var(--app-border-grey);
}

.loading_table_item_row:last-child {
  border-radius: 0 0 3px 3px;
}

.date_control {
  position: relative;
}

.date_range_container {
  position: absolute;
  top: 4px;
  z-index: 2;
}

.loading_progress_graph {
  margin-top: 30px;
  width: 80%;
  height: 80px;
  margin-bottom: 31px;
}

.empty_list_container {
  border: 1px solid var(--app-border-grey);
  border-top-width: 0;
  border-radius: 0 0 3px 3px;
}

.status_popup_container {
  position: fixed;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  display: inline-block;
  z-index: 1000;
}

.button_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.add_location_button {
  color: var(--xemelgo-blue);
  cursor: pointer;
  height: 100%;
  font-size: 16px;
  padding: 4px 12px;
  user-select: none;
}

.add_location_button:hover {
  background-color: var(--xemelgo-light-blue);
  border-radius: 3px;
}
