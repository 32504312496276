.dropdown_no_result_message {
  text-align: center;
  color: var(--app-lightgrey);
  padding: 1rem;
  font-weight: 400;
  font-size: 15px;
  margin: auto;
}

.loading_container {
  padding: 10px 20px;
}

.dropdown_item {
  padding: 12px 16px;
  cursor: pointer;
  font-weight: 400;
}

.dropdown_item:hover {
  background-color: var(--app-offwhite);
}

.dropdown_label_container {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 16px;
  max-width: 100%;
  overflow-wrap: break-word;
  scrollbar-width: none; /* For Firefox */
  white-space: normal;
  word-wrap: break-word;
  &::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
  }
}
