.top_panel_container {
  display: flex;
  align-items: center;
  padding: 15px 0;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 5px;
}

.top_panel_field_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  max-width: 250px;
}

.input_fields_container {
  display: contents;
  justify-content: space-between;
}

.status_count_section {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.status_count_label {
  font-weight: 600;
  font-size: var(--font-size-small);
  color: var(--text-primary);
  width: min-content;
}

.status_count_value {
  font-weight: 600;
  font-size: 28px;
  color: var(--app-blue);
  margin-left: 9px;
}

.clear_all_button {
  width: 95px;
  text-align: center;
  padding: 5px 0;
  border-radius: 3px;
  font-weight: 600;
  font-size: var(--font-size-small);
  color: var(--app-blue);
  border: 1px solid var(--app-blue);
}

.scan_button {
  text-align: center;
  width: 120px;
  padding: 5px 0;
  border-radius: 3px;
  font-weight: 600;
  font-size: var(--font-size-small);
  color: white;
  background-color: var(--app-blue);
  border: 1px solid var(--app-blue);
}

.buttons_container {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.loading_input {
  width: 180px;
  height: 37px;
}

.loading_input_text {
  width: 0px;
  height: 14px;
}
