.content_container {
  height: auto;
  vertical-align: top;
  display: flex;
  flex-direction: row;
  flex: 1;
}

.detail_card {
  min-width: 400px;
  max-width: 35%;
  border-right: 1px solid var(--app-border-grey);
}

.details_page_tabs {
  flex: 1;
  margin-bottom: 12px;
}
