.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button,
.button:hover {
  display: flex;
  flex-direction: row;
  text-align: center;
  background-color: var(--app-white);
  border-radius: 3px;
  color: var(--app-blue);
  height: 35px;
  padding: 0px 20px;
  font-weight: bold;
  border: 1px solid var(--app-blue);
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.button:hover {
  background-color: var(--app-lightblue);
}

.button:active {
  opacity: 0.9;
}

.button_icon {
  width: 18px;
  height: 21px;
  padding-bottom: 3px;
}
