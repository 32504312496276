.body_container {
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}
