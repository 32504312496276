.centered_popup_container {
  position: fixed;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  display: inline-block;
  z-index: 1000;
}

.table_container {
  padding: 20px;
}

.table_title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
}
