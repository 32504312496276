.title_icon {
  color: var(--xemelgo-blue);
  border: 4px solid;
  border-color: var(--xemelgo-light-blue);
  border-radius: 40px;
  padding: 3px;
}

.modal_container {
  width: 960px;
  max-width: 960px !important;
}

.location_modal {
  position: absolute;
  top: 0px;
  right: 0;
  transform: none !important;
  max-height: none;
  min-width: 900px;
  height: 100%;
  margin: 0;
}

.loading_circle {
  margin-top: 40%;
  margin-bottom: 5%;
  font-size: 1.5em;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.inner_location_title_text {
  font-weight: bold;
  margin-bottom: 10px;
}

.inner_location_container {
  padding: 26px 35px 30px 35px;
}

.inner_locations_list_container {
  border-bottom: 0.2em solid var(--app-border-grey);
  border-top: 0.2em solid var(--app-border-grey);
}

.inner_location_item {
  height: 40px;
  display: flex;
  align-items: center;
  margin-left: 10px;
}
