.title_icon {
  color: var(--xemelgo-blue);
  border: 4px solid;
  border-color: var(--xemelgo-light-blue);
  border-radius: 40px;
  padding: 3px;
}

.modal_container {
  width: 960px;
  max-width: 960px !important;
}

.top_separator {
  border-top: 1px solid var(--app-border-grey);
}
